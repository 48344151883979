var render = function () {
  var _vm$dataKondisiGiziku, _vm$dataKondisiGiziku2, _vm$dataKondisiGiziku3, _vm$dataKondisiGiziku4, _vm$dataKondisiGiziku5, _vm$dataKondisiGiziku6, _vm$dataKondisiGiziku7, _vm$dataKondisiGiziku8, _vm$dataKondisiGiziku9, _vm$dataKondisiGiziku10, _vm$dataKondisiGiziku11, _vm$dataKondisiGiziku12, _vm$dataKondisiGiziku13, _vm$dataKondisiGiziku14, _vm$dataKondisiGiziku15, _vm$dataKondisiGiziku16, _vm$dataKondisiGiziku17, _vm$dataKondisiGiziku18, _vm$dataKondisiGiziku19, _vm$dataKondisiGiziku20, _vm$dataKondisiGiziku21, _vm$dataKondisiGiziku22, _vm$dataKondisiGiziku23, _vm$dataKondisiGiziku24, _Number, _vm$nutritionalIntake, _Number2, _vm$dataKondisiGiziku25, _vm$dataKondisiGiziku26, _vm$nutritionalIntake2, _vm$nutritionalIntake3, _Number3, _vm$nutritionalIntake4, _Number4, _vm$nutritionalIntake5, _vm$nutritionalIntake6, _vm$nutritionalIntake7, _Number5, _vm$nutritionalIntake8, _Number6, _vm$nutritionalIntake9, _vm$nutritionalIntake10, _vm$nutritionalIntake11, _Number7, _vm$nutritionalIntake12, _Number8, _vm$nutritionalIntake13, _vm$nutritionalIntake14, _vm$nutritionalIntake15, _Number9, _vm$nutritionalIntake16, _Number10, _vm$nutritionalIntake17, _vm$nutritionalIntake18, _vm$nutritionalIntake19, _vm$targetAndSuggesti, _vm$targetAndSuggesti2, _vm$targetAndSuggesti3, _vm$targetAndSuggesti4, _vm$targetAndSuggesti5, _vm$targetAndSuggesti6, _vm$targetAndSuggesti7, _vm$targetAndSuggesti8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('Tabs', {
    attrs: {
      "active-tab": "profile",
      "is-program-consultation-basic": _vm.isProgramConsultationBasic,
      "tabs": _vm.tabs
    }
  }, [_c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "28px",
      "font-weight": "700"
    }
  }, [_vm._v(" Profil Gizi ")]), _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "min-width": "20px",
      "min-height": "20px",
      "width": "20px",
      "height": "20px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-account-circle.svg'),
      "fill": "#555555",
      "width": "100%",
      "height": "100%"
    }
  })], 1), _c('c-text', {
    attrs: {
      "color": "neutral.darkGray",
      "font-size": "14px",
      "line-height": "normal"
    }
  }, [_vm._v(" Dibuat oleh " + _vm._s(_vm.getFullnameAndTitle("".concat(((_vm$dataKondisiGiziku = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku === void 0 ? void 0 : (_vm$dataKondisiGiziku2 = _vm$dataKondisiGiziku.nutritionist) === null || _vm$dataKondisiGiziku2 === void 0 ? void 0 : _vm$dataKondisiGiziku2.firstName) || '', " ").concat(((_vm$dataKondisiGiziku3 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku3 === void 0 ? void 0 : (_vm$dataKondisiGiziku4 = _vm$dataKondisiGiziku3.nutritionist) === null || _vm$dataKondisiGiziku4 === void 0 ? void 0 : _vm$dataKondisiGiziku4.lastName) || '').trim(), ((_vm$dataKondisiGiziku5 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku5 === void 0 ? void 0 : (_vm$dataKondisiGiziku6 = _vm$dataKondisiGiziku5.nutritionist) === null || _vm$dataKondisiGiziku6 === void 0 ? void 0 : _vm$dataKondisiGiziku6.education) || [])) + " - Ahli gizi Dietela ")])], 1), _c('c-flex', {
    attrs: {
      "margin-top": "32px",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Kondisi Giziku ")]), _vm.isRoleNutritionist ? _c('BaseButton', {
    attrs: {
      "left-svg-icon": (_vm$dataKondisiGiziku7 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku7 !== void 0 && _vm$dataKondisiGiziku7.nutritionalConditions ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "is-disabled": !_vm.isEdited
    },
    on: {
      "click": function click($event) {
        _vm.isEdited ? _vm.$router.push({
          name: 'nutri.meal-plan.profile.edit',
          params: {
            clientId: _vm.$route.params.clientId,
            programId: _vm.$route.params.programId,
            month: _vm.$route.params.month
          },
          query: {
            tab: _vm.constants.tabs['nutritional-condition']
          }
        }) : '';
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$dataKondisiGiziku8 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku8 !== void 0 && _vm$dataKondisiGiziku8.nutritionalConditions ? 'Edit' : 'Tambah') + " ")]) : _vm._e()], 1), _c('BaseDivider'), (_vm$dataKondisiGiziku9 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku9 !== void 0 && _vm$dataKondisiGiziku9.nutritionalConditions ? _c('c-box', [_c('CardInfoKondisiGiziku', {
    attrs: {
      "type": ((_vm$dataKondisiGiziku10 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku10 === void 0 ? void 0 : (_vm$dataKondisiGiziku11 = _vm$dataKondisiGiziku10.nutritionalConditions) === null || _vm$dataKondisiGiziku11 === void 0 ? void 0 : _vm$dataKondisiGiziku11.calculationType) || '',
      "imt-status": _vm.parseInfo.status,
      "imt-value": _vm.parseInfo.value,
      "value-indicator": _vm.parseIndicatorValue,
      "popover-text": "Indeks Massa Tubuh (IMT) adalah pengukuran untuk menentukan apakah berat badan Anda sehat berdasarkan tinggi dan berat badan."
    }
  }), _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "gap": "16px",
      "flex-wrap": "wrap"
    }
  }, [_c('CardInfoOutline', {
    attrs: {
      "title": "Umur",
      "value": "".concat((_vm$dataKondisiGiziku12 = (_vm$dataKondisiGiziku13 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku13 === void 0 ? void 0 : (_vm$dataKondisiGiziku14 = _vm$dataKondisiGiziku13.nutritionalConditions) === null || _vm$dataKondisiGiziku14 === void 0 ? void 0 : _vm$dataKondisiGiziku14.age) !== null && _vm$dataKondisiGiziku12 !== void 0 ? _vm$dataKondisiGiziku12 : '-', " tahun")
    }
  }), _c('CardInfoOutline', {
    attrs: {
      "title": "Jenis kelamin",
      "value": (_vm$dataKondisiGiziku15 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku15 !== void 0 && (_vm$dataKondisiGiziku16 = _vm$dataKondisiGiziku15.nutritionalConditions) !== null && _vm$dataKondisiGiziku16 !== void 0 && _vm$dataKondisiGiziku16.gender && ((_vm$dataKondisiGiziku17 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku17 === void 0 ? void 0 : (_vm$dataKondisiGiziku18 = _vm$dataKondisiGiziku17.nutritionalConditions) === null || _vm$dataKondisiGiziku18 === void 0 ? void 0 : _vm$dataKondisiGiziku18.gender) === 'female' ? 'Perempuan' : (_vm$dataKondisiGiziku19 = _vm.dataKondisiGiziku) !== null && _vm$dataKondisiGiziku19 !== void 0 && (_vm$dataKondisiGiziku20 = _vm$dataKondisiGiziku19.nutritionalConditions) !== null && _vm$dataKondisiGiziku20 !== void 0 && _vm$dataKondisiGiziku20.gender && ((_vm$dataKondisiGiziku21 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku21 === void 0 ? void 0 : (_vm$dataKondisiGiziku22 = _vm$dataKondisiGiziku21.nutritionalConditions) === null || _vm$dataKondisiGiziku22 === void 0 ? void 0 : _vm$dataKondisiGiziku22.gender) === 'male' ? 'Laki-laki' : '-'
    }
  }), _c('CardInfoOutline', {
    attrs: {
      "title": _vm.parseInfo.cardInfo3.label,
      "value": _vm.parseInfo.cardInfo3.value
    }
  }), _c('CardInfoOutline', {
    attrs: {
      "title": "BB ideal",
      "value": _vm.parseInfo.cardInfo4.value
    }
  }), _vm.parseInfo.cardInfo5.value ? _c('CardInfoOutline', {
    attrs: {
      "title": "TB/PB ideal",
      "value": _vm.parseInfo.cardInfo5.value
    }
  }) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "width": "fit-content",
      "max-width": "50%",
      "margin-top": "16px"
    }
  }, [_c('CardInfoOutlineHighlight', {
    attrs: {
      "title": "Total Kebutuhan Energi (kkal)",
      "value": "".concat(((_vm$dataKondisiGiziku23 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku23 === void 0 ? void 0 : (_vm$dataKondisiGiziku24 = _vm$dataKondisiGiziku23.nutritionalConditions) === null || _vm$dataKondisiGiziku24 === void 0 ? void 0 : _vm$dataKondisiGiziku24.totalEnergyNeeded) || '-', " kkal")
    }
  })], 1)], 1) : _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Kondisi Giziku belum terisi"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "margin-top": "32px",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Asupan Gizi ")]), _vm.isRoleNutritionist ? _c('BaseButton', {
    attrs: {
      "left-svg-icon": _vm.nutritionalIntake ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "is-disabled": !_vm.isEdited
    },
    on: {
      "click": function click($event) {
        _vm.isEdited ? _vm.$router.push({
          name: 'nutri.meal-plan.profile.edit',
          params: {
            clientId: _vm.$route.params.clientId,
            programId: _vm.$route.params.programId,
            month: _vm.$route.params.month
          },
          query: {
            tab: _vm.constants.tabs['nutritional-intake']
          }
        }) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.nutritionalIntake ? 'Edit' : 'Tambah') + " ")]) : _vm._e()], 1), _c('BaseDivider'), _vm.nutritionalIntake ? _c('c-box', [_c('c-grid', {
    attrs: {
      "margin-top": "16px",
      "width": "100%",
      "template-columns": "repeat(2, 1fr)",
      "gap": "16px"
    }
  }, [_c('CardInfoAsupanGizi', {
    key: "energy",
    attrs: {
      "title": "Energi",
      "current": (_Number = Number((_vm$nutritionalIntake = _vm.nutritionalIntake) === null || _vm$nutritionalIntake === void 0 ? void 0 : _vm$nutritionalIntake.energyIntakeCurrent)) !== null && _Number !== void 0 ? _Number : 0,
      "requirement": (_Number2 = Number((_vm$dataKondisiGiziku25 = _vm.dataKondisiGiziku) === null || _vm$dataKondisiGiziku25 === void 0 ? void 0 : (_vm$dataKondisiGiziku26 = _vm$dataKondisiGiziku25.nutritionalConditions) === null || _vm$dataKondisiGiziku26 === void 0 ? void 0 : _vm$dataKondisiGiziku26.totalEnergyNeeded)) !== null && _Number2 !== void 0 ? _Number2 : 0,
      "status": (_vm$nutritionalIntake2 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake2 === void 0 ? void 0 : _vm$nutritionalIntake2.energyStatus,
      "note": (_vm$nutritionalIntake3 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake3 === void 0 ? void 0 : _vm$nutritionalIntake3.energyIntakeNote,
      "unit": "kkal"
    }
  }), _c('CardInfoAsupanGizi', {
    key: "protein",
    attrs: {
      "title": "Protein",
      "current": (_Number3 = Number((_vm$nutritionalIntake4 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake4 === void 0 ? void 0 : _vm$nutritionalIntake4.proteinIntakeCurrent)) !== null && _Number3 !== void 0 ? _Number3 : 0,
      "requirement": (_Number4 = Number((_vm$nutritionalIntake5 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake5 === void 0 ? void 0 : _vm$nutritionalIntake5.proteinRequirement)) !== null && _Number4 !== void 0 ? _Number4 : 0,
      "status": (_vm$nutritionalIntake6 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake6 === void 0 ? void 0 : _vm$nutritionalIntake6.proteinStatus,
      "note": (_vm$nutritionalIntake7 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake7 === void 0 ? void 0 : _vm$nutritionalIntake7.proteinIntakeNote,
      "unit": "gram"
    }
  }), _c('CardInfoAsupanGizi', {
    key: "fat",
    attrs: {
      "title": "Lemak",
      "current": (_Number5 = Number((_vm$nutritionalIntake8 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake8 === void 0 ? void 0 : _vm$nutritionalIntake8.fatIntakeCurrent)) !== null && _Number5 !== void 0 ? _Number5 : 0,
      "requirement": (_Number6 = Number((_vm$nutritionalIntake9 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake9 === void 0 ? void 0 : _vm$nutritionalIntake9.fatRequirement)) !== null && _Number6 !== void 0 ? _Number6 : 0,
      "status": (_vm$nutritionalIntake10 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake10 === void 0 ? void 0 : _vm$nutritionalIntake10.fatStatus,
      "note": (_vm$nutritionalIntake11 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake11 === void 0 ? void 0 : _vm$nutritionalIntake11.fatIntakenote,
      "unit": "gram"
    }
  }), _c('CardInfoAsupanGizi', {
    key: "carbohydrate",
    attrs: {
      "title": "Karbohidrat",
      "current": (_Number7 = Number((_vm$nutritionalIntake12 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake12 === void 0 ? void 0 : _vm$nutritionalIntake12.carboIntakeCurrent)) !== null && _Number7 !== void 0 ? _Number7 : 0,
      "requirement": (_Number8 = Number((_vm$nutritionalIntake13 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake13 === void 0 ? void 0 : _vm$nutritionalIntake13.carbohydrateRequirement)) !== null && _Number8 !== void 0 ? _Number8 : 0,
      "status": (_vm$nutritionalIntake14 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake14 === void 0 ? void 0 : _vm$nutritionalIntake14.carbohydrateStatus,
      "note": (_vm$nutritionalIntake15 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake15 === void 0 ? void 0 : _vm$nutritionalIntake15.carboIntakeNote,
      "unit": "gram"
    }
  }), _c('CardInfoAsupanGizi', {
    key: "fiber",
    attrs: {
      "title": "Serat",
      "current": (_Number9 = Number((_vm$nutritionalIntake16 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake16 === void 0 ? void 0 : _vm$nutritionalIntake16.fiberIntakeCurrent)) !== null && _Number9 !== void 0 ? _Number9 : 0,
      "requirement": (_Number10 = Number((_vm$nutritionalIntake17 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake17 === void 0 ? void 0 : _vm$nutritionalIntake17.fiberNeedsForAgeCategory)) !== null && _Number10 !== void 0 ? _Number10 : 0,
      "status": (_vm$nutritionalIntake18 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake18 === void 0 ? void 0 : _vm$nutritionalIntake18.fiberStatus,
      "note": (_vm$nutritionalIntake19 = _vm.nutritionalIntake) === null || _vm$nutritionalIntake19 === void 0 ? void 0 : _vm$nutritionalIntake19.fiberIntakeNote,
      "unit": "gram"
    }
  })], 1)], 1) : _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Asupan Gizi belum terisi"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "margin-top": "32px",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Kondisi Tubuh ")]), _vm.isRoleNutritionist ? _c('BaseButton', {
    attrs: {
      "left-svg-icon": _vm.bodyConditions.length ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "is-disabled": !_vm.isEdited
    },
    on: {
      "click": function click($event) {
        _vm.isEdited ? _vm.$router.push({
          name: 'nutri.meal-plan.profile.edit',
          params: {
            clientId: _vm.$route.params.clientId,
            programId: _vm.$route.params.programId,
            month: _vm.$route.params.month
          },
          query: {
            tab: _vm.constants.tabs['body-condition']
          }
        }) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.bodyConditions.length > 0 ? 'Edit' : 'Tambah') + " ")]) : _vm._e()], 1), _c('BaseDivider'), _vm.bodyConditions.length > 0 ? _c('c-box', [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "background-color": "primary.50",
      "color": "neutral.black",
      "font-size": "16px",
      "font-weight": "500",
      "text-align": "center",
      "border-top-left-radius": "12px",
      "border-top-right-radius": "12px",
      "margin-top": "16px",
      "margin-bottom": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "25%",
      "text-align": "center",
      "padding-block": "8px"
    }
  }, [_vm._v(" Indikator ")]), _c('c-box', {
    attrs: {
      "width": "25%",
      "text-align": "center",
      "padding-block": "8px"
    }
  }, [_vm._v(" Nilai Sekarang ")]), _c('c-box', {
    attrs: {
      "width": "25%",
      "text-align": "center",
      "padding-block": "8px"
    }
  }, [_vm._v(" Nilai Standard ")]), _c('c-box', {
    attrs: {
      "width": "25%",
      "text-align": "center",
      "padding-block": "8px"
    }
  }, [_vm._v(" Status ")])], 1), _vm._l(_vm.bodyConditions, function (item, index) {
    var _item$indicator, _item$currentConditio, _item$standardValue, _item$conclusion;

    return _c('c-grid', {
      key: index,
      attrs: {
        "w": "100%",
        "template-columns": ['repeat(4, 1fr)'],
        "gap": ['16px'],
        "border-bottom-width": "1px",
        "border-bottom-color": "neutral.superLightGray",
        "padding-block": "4px",
        "margin-bottom": "8px",
        "text-align": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "padding-block": "8px",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s((_item$indicator = item === null || item === void 0 ? void 0 : item.indicator) !== null && _item$indicator !== void 0 ? _item$indicator : '-') + " ")]), _c('c-box', {
      attrs: {
        "padding-block": "8px",
        "text-transform": "lowercase"
      }
    }, [_vm._v(" " + _vm._s((_item$currentConditio = item === null || item === void 0 ? void 0 : item.currentCondition) !== null && _item$currentConditio !== void 0 ? _item$currentConditio : '-') + " " + _vm._s(item === null || item === void 0 ? void 0 : item.currentConditionUnit) + " ")]), _c('c-box', {
      attrs: {
        "padding-block": "8px",
        "text-transform": "lowercase"
      }
    }, [_vm._v(" " + _vm._s((_item$standardValue = item === null || item === void 0 ? void 0 : item.standardValue) !== null && _item$standardValue !== void 0 ? _item$standardValue : '-') + " " + _vm._s(item === null || item === void 0 ? void 0 : item.standardValueUnit) + " ")]), _c('c-box', {
      attrs: {
        "padding-block": "8px",
        "font-weight": "500",
        "background-color": _vm.getBobyConditionStatusColor(item === null || item === void 0 ? void 0 : item.conclusion).background,
        "color": _vm.getBobyConditionStatusColor(item === null || item === void 0 ? void 0 : item.conclusion).color
      }
    }, [_vm._v(" " + _vm._s((_item$conclusion = item === null || item === void 0 ? void 0 : item.conclusion) !== null && _item$conclusion !== void 0 ? _item$conclusion : '-') + " ")])], 1);
  })], 2) : _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Kondisi Tubuh belum terisi"
    }
  })], 1), !_vm.targetAndSuggestions ? _c('c-box', [_c('c-flex', {
    attrs: {
      "margin-top": "32px",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Target dan Saran ")]), _vm.isRoleNutritionist ? _c('BaseButton', {
    attrs: {
      "left-svg-icon": _vm.targetAndSuggestions ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "is-disabled": !_vm.isEdited
    },
    on: {
      "click": function click($event) {
        _vm.isEdited ? _vm.$router.push({
          name: 'nutri.meal-plan.profile.edit',
          params: {
            clientId: _vm.$route.params.clientId,
            programId: _vm.$route.params.programId,
            month: _vm.$route.params.month
          },
          query: {
            tab: _vm.constants.tabs['target-and-suggestion']
          }
        }) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.targetAndSuggestions ? 'Edit' : 'Tambah') + " ")]) : _vm._e()], 1), _c('BaseDivider'), _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('NoData', {
    attrs: {
      "text": "Target dan Saran belum terisi"
    }
  })], 1)], 1) : _c('c-box', [_c('c-text', {
    attrs: {
      "margin-top": "16px",
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Tujuan Mealplan ")]), _c('c-text', {
    attrs: {
      "margin-top": "10px",
      "color": "neutral.superDarkGray",
      "font-size": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.targetAndSuggestions.mealPlanGoals) + " ")]), _c('c-flex', {
    attrs: {
      "gap": "16px",
      "margin-top": "16px"
    }
  }, [_c('CardInfoThingsThatNeed', {
    attrs: {
      "label": "Yang perlu dipertahankan",
      "items": ((_vm$targetAndSuggesti = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti === void 0 ? void 0 : _vm$targetAndSuggesti.thingsThatNeedToBeMaintained) || ''
    }
  }), _c('CardInfoThingsThatNeed', {
    attrs: {
      "color": "warning",
      "label": "Yang harus diperbaiki",
      "items": ((_vm$targetAndSuggesti2 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti2 === void 0 ? void 0 : _vm$targetAndSuggesti2.thingsThatNeedToBeImproved) || ''
    }
  })], 1), _c('c-flex', {
    attrs: {
      "margin-top": "32px",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Target ")]), _vm.isRoleNutritionist ? _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-edit-2.svg'),
      "left-svg-icon-color": "white",
      "is-disabled": !_vm.isEdited
    },
    on: {
      "click": function click($event) {
        _vm.isEdited ? _vm.$router.push({
          name: 'nutri.meal-plan.profile.edit',
          params: {
            clientId: _vm.$route.params.clientId,
            programId: _vm.$route.params.programId,
            month: _vm.$route.params.month
          },
          query: {
            tab: _vm.constants.tabs['target-and-suggestion']
          }
        }) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.targetAndSuggestions ? 'Edit' : 'Tambah') + " ")]) : _vm._e()], 1), _c('BaseDivider'), typeof ((_vm$targetAndSuggesti3 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti3 === void 0 ? void 0 : _vm$targetAndSuggesti3.targetForThisPeriod) === 'string' ? _c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$targetAndSuggesti4 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti4 === void 0 ? void 0 : _vm$targetAndSuggesti4.targetForThisPeriod)
    }
  }) : _c('c-box', {
    attrs: {
      "as": "ol",
      "padding-left": "24px"
    }
  }, _vm._l(((_vm$targetAndSuggesti5 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti5 === void 0 ? void 0 : _vm$targetAndSuggesti5.targetForThisPeriod) || [], function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "as": "li"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "neutral.superDarkGray",
        "font-size": "16px"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }), 1), _c('c-flex', {
    attrs: {
      "margin-top": "32px",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" Saran - saran ")])], 1), _c('BaseDivider'), typeof ((_vm$targetAndSuggesti6 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti6 === void 0 ? void 0 : _vm$targetAndSuggesti6.nutritionAndLifestyleAdvice) === 'string' ? _c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$targetAndSuggesti7 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti7 === void 0 ? void 0 : _vm$targetAndSuggesti7.nutritionAndLifestyleAdvice)
    }
  }) : _c('c-box', {
    attrs: {
      "as": "ol",
      "padding-left": "24px"
    }
  }, _vm._l(((_vm$targetAndSuggesti8 = _vm.targetAndSuggestions) === null || _vm$targetAndSuggesti8 === void 0 ? void 0 : _vm$targetAndSuggesti8.nutritionAndLifestyleAdvice) || [], function (item, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "as": "li"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "neutral.superDarkGray",
        "font-size": "16px"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }), 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "margin-top": "16px",
      "background-color": "secondary.50",
      "padding-block": "20px",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "max-width": "330px",
      "flex-direction": "column",
      "align-items": "center",
      "gap": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "#111",
      "font-weight": "500"
    }
  }, [_vm._v(" Temukan berbagai variasi golongan makan beserta takaran porsinya ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/images/image-lightbulb-moment.png'),
      "width": ['120px']
    }
  })], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "30px",
      "height": "47px",
      "font-weight": "500"
    },
    on: {
      "click": function click($event) {
        _vm.showModalDirectGlossary = true;
      }
    }
  }, [_vm._v(" Lihat Daftar & Nilai Gizi Makanan ")])], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.showModalDirectGlossary,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "4px"
          }
        }, [_vm._v(" Anda yakin untuk dialihkan ke halaman Nilai Gizi? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.showModalDirectGlossary = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: _vm.isRoleNutritionist ? 'client.glossaries' : 'admin.glossaries'
              });
            }
          }
        }, [_vm._v(" Lanjutkan ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }